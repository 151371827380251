<template>
  <div style="height: 100%;overflow: hidden;">
    <van-loading size="24px" style="text-align:center;top:50px;" v-show="showPdf">正在加载</van-loading>
	  <div id="app"><div id="demo"></div></div>
  </div>
</template>
 
<script>
// import wx from 'weixin-js-sdk';
import Pdfh5 from 'pdfh5';
import axios from 'axios';
  export default {
    data () {
      return {
				pdfh5: null,
        agreementUrl:this.$route.query.url,
        contractNo:this.$route.query.contractNo,
        showPdf:true,
       }
    },
    mounted(){
      // let that = this;
      // sessionStorage.setItem('key', 'contract4');
      // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      //   if(sessionStorage.getItem('key') == 'contract4') {
      //     that.$router.push({ path: '/contractQueryDetail',query:{contractNo:that.contractNo}});
      //   }
      // }, false);
    },
    created(){
      // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
      // var url = this.agreementUrl+'?Authorization=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqaW5qbSIsImF1ZCI6ImppbmptIiwiaHpJZCI6IjExMDAiLCJleHAiOjgzMjExNzI0MjQsImlhdCI6MTY1NDUwNTc1OCwiY2hhbm5lbElkIjoiMTEwMCIsInVzZXJuYW1lIjoiamluam0ifQ.PZN3w_-lUFRUvCVUdnN2b9tsqHfBXP30noCGVgWTCso';
      var url = this.agreementUrl+'?Authorization='+localStorage.getItem("token");
      axios.get(url, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        this.pdfh5 = new Pdfh5('#demo', {
          data: res.data
        });
        this.showPdf = false;
      });
　　},
    method: {

    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
